

const handleInteriorSlideshow = () => {
    
    const containers = document.querySelectorAll(".interior-container");
    if (containers) {
        containers.forEach((container, index) => {
            const imagesList = container.querySelectorAll(".images-list");
            const titleList = container.querySelectorAll(".interior-title");
            const descriptionList = container.querySelectorAll(".interior-description");
            container.classList.add('select-none')
            const leftBtn = container.getElementsByClassName("left-btn")[0];
            const leftPath = leftBtn.querySelector("path");

            const rightBtn = container.getElementsByClassName("right-btn")[0];

            const rightPath = rightBtn.querySelector("path");

            container.addEventListener('swiped-left', nextImg)
            container.addEventListener('swiped-right', prevImg)

            leftBtn.addEventListener("click", prevImg)
            rightBtn.addEventListener("click", nextImg)

            function prevImg () {
                imagesList.forEach((image, index) => {
                    if (image.classList.contains("animate-imgFadeInr2l") || (image.classList.contains("animate-imgFadeInl2r"))) {
                        if (image.previousElementSibling) {
                            const delayTimer = 500;
                            if (index != 0){leftPath.classList.add("animate-r2l");}
                            if (leftBtn.classList.contains("disabled")) {
                                leftBtn.classList.remove("cursor-default", "disabled", "opacity-50");
                                leftBtn.classList.add("cursor-pointer");
                            }
                            if (index == 1){
                                leftBtn.classList.add("cursor-default", "disabled", "opacity-50");
                                leftBtn.classList.remove("cursor-pointer");
                            }
                            else if (rightBtn.classList.contains("disabled")) {
                                rightBtn.classList.remove("cursor-default", "disabled", "opacity-50");
                                rightBtn.classList.add("cursor-pointer");
                            }
                            image.classList.replace("animate-imgFadeInl2r", "animate-imgFadeOutr2l");
                            image.classList.replace("animate-imgFadeInr2l", "animate-imgFadeOutr2l");
                            image.previousElementSibling.classList.replace("animate-imgFadeOutl2r", "animate-imgFadeInr2l");
                            image.previousElementSibling.classList.replace("animate-imgFadeOutr2l", "animate-imgFadeInr2l");
                            container.getElementsByClassName('img-number')[0].innerText = index.toString().padStart(2, '0');
                            setTimeout(function(){
                                leftPath.classList.remove("animate-r2l");
                            }, delayTimer);
                        }
                        else{
                            leftBtn.classList.add("cursor-default", "disabled", "opacity-50");
                            leftBtn.classList.remove("cursor-pointer");
                        }
                    }
                        
                });
                titleList.forEach((title, index) => {
                    if (title.previousElementSibling) {
                        
                        if (title.classList.contains("animate-titleFadeInr2l") || (title.classList.contains("animate-titleFadeInl2r"))) {
                            title.classList.replace("animate-titleFadeInl2r", "animate-titleFadeOutr2l");
                            title.classList.replace("animate-titleFadeInr2l", "animate-titleFadeOutr2l");
                            title.previousElementSibling.classList.replace("animate-titleFadeOutl2r", "animate-titleFadeInr2l");
                            title.previousElementSibling.classList.replace("animate-titleFadeOutr2l", "animate-titleFadeInr2l");
                        }; 
                    };
                });   
                descriptionList.forEach((description, index) => {
                    if (description.previousElementSibling) {
                        
                        if (description.classList.contains("animate-titleFadeInr2l") || (description.classList.contains("animate-titleFadeInl2r"))) {
                            description.classList.replace("animate-titleFadeInl2r", "animate-titleFadeOutr2l");
                            description.classList.replace("animate-titleFadeInr2l", "animate-titleFadeOutr2l");
                            description.previousElementSibling.classList.replace("animate-titleFadeOutl2r", "animate-titleFadeInr2l");
                            description.previousElementSibling.classList.replace("animate-titleFadeOutr2l", "animate-titleFadeInr2l");
                        }; 
                    };
                });   
            };

            
            function nextImg () {
                [].slice.call(imagesList).reverse().forEach((image, index) => {
                    if (image.classList.contains("animate-imgFadeInr2l") || (image.classList.contains("animate-imgFadeInl2r"))) {
                        if (image.nextElementSibling) {
                            const delayTimer = 500;
                            if (index != 0){rightPath.classList.add("animate-l2r");}
                            if (rightBtn.classList.contains("disabled")) {
                                rightBtn.classList.remove("cursor-default", "disabled", "opacity-50");
                                rightBtn.classList.add("cursor-pointer");
                            }
                            if (index == 1) {
                                rightBtn.classList.add("cursor-default", "disabled", "opacity-50");
                                rightBtn.classList.remove("cursor-pointer");
                            }
                            else if (leftBtn.classList.contains("disabled")) {
                                leftBtn.classList.remove("cursor-default", "disabled", "opacity-50");
                                leftBtn.classList.add("cursor-pointer");
                            }
                            
                            image.classList.replace("animate-imgFadeInr2l", "animate-imgFadeOutl2r");
                            image.classList.replace("animate-imgFadeInl2r", "animate-imgFadeOutl2r");
                            image.nextElementSibling.classList.replace("animate-imgFadeOutr2l", "animate-imgFadeInl2r");
                            image.nextElementSibling.classList.replace("animate-imgFadeOutl2r", "animate-imgFadeInl2r");
                            container.getElementsByClassName('img-number')[0].innerText = ((imagesList.length + 1)  - index).toString().padStart(2, '0');
                            setTimeout(function(){
                                rightPath.classList.remove("animate-l2r");
                            }, delayTimer);
                        }
                        else{
                            rightBtn.classList.add("cursor-default", "disabled", "opacity-50");
                            rightBtn.classList.remove("cursor-pointer");
                        }
                    }
                        
                });
                [].slice.call(titleList).reverse().forEach((title, index) => {
                    if (title.nextElementSibling) {
                        
                        if (title.classList.contains("animate-titleFadeInr2l") || (title.classList.contains("animate-titleFadeInl2r"))) {
                            title.classList.replace("animate-titleFadeInl2r", "animate-titleFadeOutl2r");
                            title.classList.replace("animate-titleFadeInr2l", "animate-titleFadeOutl2r");
                            title.nextElementSibling.classList.replace("animate-titleFadeOutl2r", "animate-titleFadeInl2r");
                            title.nextElementSibling.classList.replace("animate-titleFadeOutr2l", "animate-titleFadeInl2r");
                        }; 
                    };
                });        
                [].slice.call(descriptionList).reverse().forEach((description, index) => {
                    if (description.nextElementSibling) {
                        
                        if (description.classList.contains("animate-titleFadeInr2l") || (description.classList.contains("animate-titleFadeInl2r"))) {
                            description.classList.replace("animate-titleFadeInl2r", "animate-titleFadeOutl2r");
                            description.classList.replace("animate-titleFadeInr2l", "animate-titleFadeOutl2r");
                            description.nextElementSibling.classList.replace("animate-titleFadeOutl2r", "animate-titleFadeInl2r");
                            description.nextElementSibling.classList.replace("animate-titleFadeOutr2l", "animate-titleFadeInl2r");
                        }; 
                    };
                });        
            };
        });
    }
};
  
  export default handleInteriorSlideshow;
  