// patch CustomEvent to allow constructor creation (IE/Chrome)
    if (typeof window.CustomEvent !== 'function') {

    window.CustomEvent = function (event, params) {

        params = params || { bubbles: false, cancelable: false, detail: undefined };

        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    };

    window.CustomEvent.prototype = window.Event.prototype;
}

document.addEventListener('touchstart', handleTouchStart, false);
document.addEventListener('touchmove', handleTouchMove, false);
document.addEventListener('touchend', handleTouchEnd, false);

var xDown = null;
var yDown = null;
var xDiff = null;
var yDiff = null;
var timeDown = null;
var startEl = null;

/**
 * Fires swiped event if swipe detected on touchend
 * @param {object} e - browser event object
 * @returns {void}
 */
function handleTouchEnd(e) {

    // if the user released on a different target, cancel!
    if (startEl !== e.target) return;

    var swipeThreshold = parseInt(getNearestAttribute(startEl, 'data-swipe-threshold', '20'), 10); // default 20px
    var swipeTimeout = parseInt(getNearestAttribute(startEl, 'data-swipe-timeout', '500'), 10);    // default 500ms
    var timeDiff = Date.now() - timeDown;
    var eventType = '';
    var changedTouches = e.changedTouches || e.touches || [];

    if (Math.abs(xDiff) > Math.abs(yDiff)) { // most significant
        if (Math.abs(xDiff) > swipeThreshold && timeDiff < swipeTimeout) {
            if (xDiff > 0) {
                eventType = 'swiped-left';
            }
            else {
                eventType = 'swiped-right';
            }
        }
    }
    else if (Math.abs(yDiff) > swipeThreshold && timeDiff < swipeTimeout) {
        if (yDiff > 0) {
            eventType = 'swiped-up';
        }
        else {
            eventType = 'swiped-down';
        }
    }

    if (eventType !== '') {

        var eventData = {
            dir: eventType.replace(/swiped-/, ''),
            touchType: (changedTouches[0] || {}).touchType || 'direct',
            xStart: parseInt(xDown, 10),
            xEnd: parseInt((changedTouches[0] || {}).clientX || -1, 10),
            yStart: parseInt(yDown, 10),
            yEnd: parseInt((changedTouches[0] || {}).clientY || -1, 10)
        };

        // fire `swiped` event event on the element that started the swipe
        startEl.dispatchEvent(new CustomEvent('swiped', { bubbles: true, cancelable: true, detail: eventData }));

        // fire `swiped-dir` event on the element that started the swipe
        startEl.dispatchEvent(new CustomEvent(eventType, { bubbles: true, cancelable: true, detail: eventData }));
    }

    // reset values
    xDown = null;
    yDown = null;
    timeDown = null;
}

/**
 * Records current location on touchstart event
 * @param {object} e - browser event object
 * @returns {void}
 */
function handleTouchStart(e) {

    // if the element has data-swipe-ignore="true" we stop listening for swipe events
    if (e.target.getAttribute('data-swipe-ignore') === 'true') return;

    startEl = e.target;

    timeDown = Date.now();
    xDown = e.touches[0].clientX;
    yDown = e.touches[0].clientY;
    xDiff = 0;
    yDiff = 0;
}

/**
 * Records location diff in px on touchmove event
 * @param {object} e - browser event object
 * @returns {void}
 */
function handleTouchMove(e) {

    if (!xDown || !yDown) return;

    var xUp = e.touches[0].clientX;
    var yUp = e.touches[0].clientY;

    xDiff = xDown - xUp;
    yDiff = yDown - yUp;
}

/**
 * Gets attribute off HTML element or nearest parent
 * @param {object} el - HTML element to retrieve attribute from
 * @param {string} attributeName - name of the attribute
 * @param {any} defaultValue - default value to return if no match found
 * @returns {any} attribute value or defaultValue
 */
function getNearestAttribute(el, attributeName, defaultValue) {

    // walk up the dom tree looking for attributeName
    while (el && el !== document.documentElement) {

        var attributeValue = el.getAttribute(attributeName);

        if (attributeValue) {
            return attributeValue;
        }

        el = el.parentNode;
    }

    return defaultValue;
}

const handleSlideshow = () => {
    
    const containers = document.querySelectorAll(".project-container");
    // console.log(containers);
    if (containers) {
        containers.forEach((container, index) => {
            // console.log("HELLO I FOUND SLIDESHOW");
            const imagesList = container.querySelectorAll(".images-list");
            if (imagesList.length > 1) {
                container.classList.add('select-none')
                // console.log(imagesList);
                const leftBtn = container.getElementsByClassName("left-btn")[0];
                const leftCircle = leftBtn.querySelector("circle");
                const leftPath = leftBtn.querySelector("path");

                const rightBtn = container.getElementsByClassName("right-btn")[0];

                const rightCircle = rightBtn.querySelector("circle");
                const rightPath = rightBtn.querySelector("path");

                container.addEventListener('swiped-left', nextImg)
                container.addEventListener('swiped-right', prevImg)

                leftBtn.addEventListener("click", prevImg)
                rightBtn.addEventListener("click", nextImg)

                function prevImg () {
                    imagesList.forEach((image, index) => {
                        // OLD ANIMATION
                        // if (image.classList.contains("animate-imgFadeInr2l") || (image.classList.contains("animate-imgFadeInl2r"))) {
                        if (image.classList.contains("animate-imgFadeIn")) {
                            if (image.previousElementSibling) {
                                // if (index != 0){leftPath.classList.add("animate-r2l");}
                                if (leftBtn.classList.contains("disabled")) {
                                    leftBtn.classList.remove("cursor-default", "disabled", "opacity-30");
                                    leftBtn.classList.add("cursor-pointer");
                                }
                                if (index == 1){
                                    leftBtn.classList.add("cursor-default", "disabled", "opacity-30");
                                    leftBtn.classList.remove("cursor-pointer");
                                }
                                if (rightBtn.classList.contains("disabled")) {
                                    rightBtn.classList.remove("cursor-default", "disabled", "opacity-30");
                                    rightBtn.classList.add("cursor-pointer");
                                }
                                // OLD ANIMATION
                                // image.classList.replace("animate-imgFadeInl2r", "animate-imgFadeOutr2l");
                                // image.classList.replace("animate-imgFadeInr2l", "animate-imgFadeOutr2l");
                                // image.previousElementSibling.classList.replace("animate-imgFadeOutl2r", "animate-imgFadeInr2l");
                                // image.previousElementSibling.classList.replace("animate-imgFadeOutr2l", "animate-imgFadeInr2l");
                                image.classList.replace("animate-imgFadeIn", "animate-imgFadeOut");
                                image.previousElementSibling.classList.replace("animate-imgFadeOut", "animate-imgFadeIn");
                                
                                // NUMBERS REMOVED
                                // container.getElementsByClassName('img-number')[0].innerText = index.toString().padStart(2, '0');
                                // setTimeout(function(){
                                //     leftPath.classList.remove("animate-r2l");
                                // }, delayTimer);
                            }
                            else{
                                leftBtn.classList.add("cursor-default", "disabled", "opacity-30");
                                leftBtn.classList.remove("cursor-pointer");
                            }
                        }
                            
                    });
                };
                    // container.parentNode.nextElementSibling.classList.toggle('animate-imgFadeIn');
                    // array.forEach(element => {
                    //     if (index>0) {
                    //         console.log("LEFT CLICK");
                    //     }
                    // });
                // });

                
                function nextImg () {
                    [].slice.call(imagesList).reverse().forEach((image, index) => {
                        if (image.classList.contains("animate-imgFadeIn")) {
                        // OLD ANIMATION
                        // if (image.classList.contains("animate-imgFadeInr2l") || (image.classList.contains("animate-imgFadeInl2r"))) {
                            if (image.nextElementSibling) {
                                // if (index != 0){rightPath.classList.add("animate-l2r");}
                                if (rightBtn.classList.contains("disabled")) {
                                    rightBtn.classList.remove("cursor-default", "disabled", "opacity-30");
                                    rightBtn.classList.add("cursor-pointer");
                                }
                                if (index == 1) {
                                    rightBtn.classList.add("cursor-default", "disabled", "opacity-30");
                                    rightBtn.classList.remove("cursor-pointer");
                                }
                                if (leftBtn.classList.contains("disabled")) {
                                    leftBtn.classList.remove("cursor-default", "disabled", "opacity-30");
                                    leftBtn.classList.add("cursor-pointer");
                                }
                                // if (image.nextElementSibling.classList.contains("invisible")) {
                                //     image.classList.toggle("invisible", "visible");
                                // }
                                // OLD ANIMATION
                                // image.classList.replace("animate-imgFadeInr2l", "animate-imgFadeOutl2r");
                                // image.classList.replace("animate-imgFadeInl2r", "animate-imgFadeOutl2r");
                                // image.nextElementSibling.classList.replace("animate-imgFadeOutr2l", "animate-imgFadeInl2r");
                                // image.nextElementSibling.classList.replace("animate-imgFadeOutl2r", "animate-imgFadeInl2r");
                                image.classList.replace("animate-imgFadeIn", "animate-imgFadeOut");
                                image.nextElementSibling.classList.replace("animate-imgFadeOut", "animate-imgFadeIn");
                                
                                // NUMBERS REMOVED
                                // container.getElementsByClassName('img-number')[0].innerText = ((imagesList.length + 1)  - index).toString().padStart(2, '0');
                                // setTimeout(function(){
                                //     rightPath.classList.remove("animate-l2r");
                                // }, delayTimer);
                            }
                            else{
                                rightBtn.classList.add("cursor-default", "disabled", "opacity-30");
                                rightBtn.classList.remove("cursor-pointer");
                            }
                        }
                            
                    });
                };
            
            };
        });
    }
};
  
  export default handleSlideshow;
  