export const handleModal = (element) => {
  const root = document.documentElement;
  const formWrapper = element.querySelector('.Modal__wrapper');
  const closeButton = element.querySelector('.Modal__close');

  const closeModal = () => {
    element.classList.remove('is-active');
    root.style.overflow = 'visible';

    // Reset form when close.
    [...element.querySelectorAll('.is-submitted')]
      .map(el => el.classList.remove('is-submitted'))
  };

  element.addEventListener('click', closeModal);
  closeButton.addEventListener('click', closeModal);

  formWrapper.addEventListener('click', (event) => {
    event.stopPropagation();
  });
};
