//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.iterator";

import handleReadyState from '@/components/ReadyState';
import handleAccordions from '@/components/Accordion';
import handleSlideshow from '@/components/Slideshow';
import handleInteriorSlideshow from '@/components/interiorSlideshow';
import handleGallery from '@/components/Gallery';
import { handleModal } from '@/components/Modal';
import { handleFormLeads } from '@/components/FormLeads';

//import { createPropertyPicker } from '@/components/PropertyPicker';

// Fonts
import '@/fonts/ShapeSans/Regular.woff2';
import '@/fonts/ShapeSans/Regular.woff';

import '@/fonts/ShapeSans/Display.woff2';
import '@/fonts/ShapeSans/Display.woff';

import '@/fonts/ShapeSans/Medium.woff2';
import '@/fonts/ShapeSans/Medium.woff';
//
// Application
//
handleReadyState();
handleAccordions();
handleSlideshow();
handleInteriorSlideshow();

const ProjectName = {
  // Options / globals
  body: null,
  formPropertyPicker: null,
  MARKETER_BASE_URL: null,

  init() {
  //  this.MARKETER_BASE_URL = SKANSKA_ENV.MARKETER_BASE_URL || null;
    this.body = document.body;
    document.body.style.visibility = null;
    handleGallery();

    // Initilize modals
    [...this.body.querySelectorAll('.Modal')].forEach(el => handleModal(el));

    // Initilize leads form
    [...this.body.querySelectorAll('form.FormLeads')].map(el => handleFormLeads(el));
    //this.formPropertyPicker = this.body.querySelector('form[data-for="PropertyPicker"]');
    //console.log(this.formPropertyPicker)
    //
    // Init project picker
    //
    /*const propertyPickerEl = document.querySelector('.PropertyPicker');
    if (propertyPickerEl) {
      const project = JSON.parse(propertyPickerEl.dataset?.project);
      const views = JSON.parse(propertyPickerEl.dataset?.views);
      const settings = JSON.parse(propertyPickerEl.dataset?.settings);
      const lang = propertyPickerEl.dataset?.lang;
      const startview = propertyPickerEl.dataset?.startview;
      const listColumns = JSON.parse(propertyPickerEl.dataset?.listColumns);

      if(!project || !views.length || !settings) {
        return;
      }

      const onRegisterInterestClick = (uuid) => {
        // ON UNIT PICKED
        // Skip if leads form
        if(!this.formPropertyPicker) {
          return;
        }

        if(this.formPropertyPicker.elements['unitUuid']) {
          this.formPropertyPicker.elements['unitUuid'].value = uuid;
        }

        // TODO: This is temporary because of missing marketer functionality
        if(this.formPropertyPicker.elements['message']) {
          function getUnit(unitUUID) {
            for (let building of project.buildings) {
              for (let unit of building.units) {
                if (unit.uuid === unitUUID) {
                  return unit;
                }
              }
            }
          }



          const unit = getUnit(uuid);
          const stage = project?.stages?.find(a => a.uuid === unit.stageId)?.name || null;

          this.formPropertyPicker.elements['message'].value = `Jeg er interessert i enhet ${unit.unitId}${stage ? ` i salgtrinn ${stage}`: ''} i prosjekt ${project?.name}.`;
        }

        // Lock body
        const modal = this.formPropertyPicker.closest('.Modal');
        if(modal) {
          const root = document.documentElement;
          root.style.overflow = 'hidden';
          // Show modal
          modal.classList.add('is-active');
        }
      }

      const onMakeOfferClick = (uuid) => {
        // Skip if URL not exist
        if(!this.MARKETER_BASE_URL) {
          return;
        }

        // Open bid in new tab
        window.open(`${this.MARKETER_BASE_URL}/${project.slug}/offers/units/?start=true&property_id=${uuid}`, '_blank');
      }

      createPropertyPicker(propertyPickerEl, {
        project,
        views,
        settings,
        lang,
        startview,
        listColumns,
        onRegisterInterestClick,
        onMakeOfferClick
      });
    }*/
  },

  initOnLoad() {
    console.log('onload');
  }
};

document.addEventListener('DOMContentLoaded', () => ProjectName.init());
window.addEventListener('load', () => ProjectName.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
