const handleAccordions = () => {
  const accordions = document.querySelectorAll(".accordion-btn");
  if (accordions) {
    accordions.forEach((accordion) => {
      const accordionButton = accordion.childNodes[0];
      accordionButton.addEventListener("click", () => {
        var parent = accordion.parentNode
        console.log(parent)
        var info = accordion.getElementsByClassName("info")[0]
        console.log(info.classList)
        if (info.classList.contains("max-h-0")) {
            info.classList.remove("max-h-0")
            parent.classList.toggle("maximized")
        }
        else{
            parent.classList.toggle("maximized")
            parent.classList.toggle("minimized")
        }
      });
    });
  }
};
    // }
// const handleAccordions = () => {
//   const accordions = document.querySelectorAll(".accordion-btn");
//   if (accordions) {
//     accordions.forEach((elem) => {
//       var parent = elem.parentNode
//       console.log(parent)
//       var info = elem.getElementsByClassName("info")[0]
//       console.log(info.classList)
//       if (info.classList.contains("max-h-0")) {
//           info.classList.remove("max-h-0")
//           parent.classList.toggle("maximized")
//       }
//       else{
//           parent.classList.toggle("maximized")
//           parent.classList.toggle("minimized")
//       }
//     });
//   }
// };

export default handleAccordions;
