const handleReadyState = () => {
    var video = document.getElementById("hero_video");
    if (video) {

        if ( video.readyState === 4 ) {
            document.body.classList.add("ready");
        }
    }
    else{
        document.body.classList.add("ready");
    }
  };

  export default handleReadyState;
